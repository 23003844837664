<template>
  <div class="property">
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/Frame9617.png"
      alt=""
      class="property-back"
      @click="back()"
    />
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/1413a69cacb7ba1c1bc1348e58696642.png"
      alt=""
      class="property-but"
    />
    <div class="property-type flex">
      <div class="flex" style="flex-wrap: wrap; overflow: auto">
        <div
          v-for="(item, index) in typeList"
          :key="index"
          class="property-type-li"
          @click="listButton(item)"
        >
          <div class="property-type-li-text">{{ item.name }}</div>
          <img :src="item.img" alt="" class="property-type-li-img" />
          <!-- <el-image
            :src="item.img"
            alt=""
            class="property-type-li-img"
          ></el-image> -->
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      typeList: [],
    };
  },
  mounted() {
    this.typeList = JSON.parse(this.$route.query.data).children;
    // console.log(this.typeList);
  },
  methods: {
    listButton(item) {
      this.$router.push({
        path: "/marketplace/property/propertyTpye",
        query: { data: JSON.stringify(item.children) },
      });
    },
    back() {
      history.back();
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.property::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.property {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/marketplace/consignmentsales.png")
    no-repeat;
  background-size: 100% 100%;
  // position: relative;
  display: flex;
  justify-content: center;
  overflow: auto;

  &-but {
    position: absolute;
    right: 20px;
    bottom: 0;
    width: 203px;
    height: 413px;
  }
  &-back:hover {
    cursor: pointer;
  }
  &-back {
    position: absolute;
    top: 163px;
    left: 90px;
    width: 72px;
    height: 72px;
  }
  &-type::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  div::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &-type {
    width: 1100px;
    padding-top: 103px;
    justify-content: center;
    padding-bottom: 30px;
    overflow: auto;
    &-li:nth-child(2n) {
      margin-right: 0;
    }
    &-li::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    &-li:hover {
      cursor: pointer;
      // width: 515px;
      // height: 342px;
      // //   margin-right: 42px;
      // text-align: center;
      // background: url("https://cdn.bubbleplan.cn/static/marketplace/Group122200272x.png")
      //   no-repeat;
      // background-size: 100% 100%;
      // flex-direction: column;
      // align-items: center;
      // justify-content: center;
      // margin-bottom: 15px;
    }
    &-li {
      width: 540px;
      height: 297px;
      margin-right: 12px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10547.png")
        no-repeat;
      background-size: 100% 100%;
      margin-bottom: 15px;
      padding: 40px 55px;
      &-img {
        width: 191px;
        height: 191px;
        display: inline-block;
        margin-left: 119px;
        margin-top: -20px;
      }
      &-text {
        color: #fef5de;
        font-size: 30px;
        font-family: "qiantuhouheiti";
        text-shadow: -3px -3px 0 #341030, 3px -3px 0 #341030, -3px 3px 0 #341030,
          3px 3px 0 #341030;
        /* 模拟描边效果 */
      }
    }
  }
}
</style>